import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      logoLoop: imageSharp(fluid: {originalName: { regex: "/lp-nter-loop-logo/" }}) {
        fluid(maxWidth: 185, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bussiness: imageSharp(fluid: {originalName: { regex: "/lpInterLoopBussiness/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      gold: imageSharp(fluid: {originalName: { regex: "/lpInterLoopPointsCardGold/" }}) {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      platinum: imageSharp(fluid: {originalName: { regex: "/lpInterLoopPointsCardPlatinum/" }}) {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      black: imageSharp(fluid: {originalName: { regex: "/lpInterLoopPointsCardBalck/" }}) {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      win: imageSharp(fluid: {originalName: { regex: "/lpInterLoopPointsCardWin/" }}) {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
