import React from 'react'

import FAQ from 'src/components/Faq'

import pageContext from '../../pageContext.json'

import { Section } from './style'

const Faq = () => {
    return (
      <Section className='pt-5 pb-md-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='fs-24 lh-30 fs-xl-40 lh-xl-50 text-grayscale--500 mb-4'>
                Dúvidas Frequentes sobre o Inter Loop
              </h3>
              <FAQ
                answerData={pageContext.structuredData.faq}
                columns={{ xl: 1, lg: 1 }}
                searchBg='#F5F6FA'
              />
            </div>
          </div>
        </div>
      </Section>
    )
}

export default Faq
