import styled from 'styled-components'
import { white, grayscale } from 'src/styles/colors'

export const Section = styled.div`
  background: ${white};

  a {
    text-decoration: underline;
    color: ${grayscale[400]};
  }
`
