import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  min-height: 905px;
  display: flex;
  align-items: center;

  .text-header {
    margin-bottom: 32px;

    h2 {
      margin-bottom: 16px;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    min-height: 660px;

    .text-header {
      margin-bottom: 40px;
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 630px;

    .text-header h2 {
      margin-bottom: 24px;
    }

  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 727px;

    .text-header h2 {
      margin-bottom: 30px;
    }
  }
`
export const Card = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 24px;

  &:not(.item-0) {
    padding-top: 24px;
    border-top: 1px solid ${grayscale[200]};
  }

  @media (min-width: ${breakpoints.md}) {
    border: 1px solid ${grayscale[200]};
    border-radius: 8px;
    padding: 24px 20px;
    min-height: 235px;

    .item-2, .item-3 {
      margin-bottom: 0;
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 356px;
    margin-bottom: 0;
  }
  @media (min-width: ${breakpoints.xl}) {
    padding: 26px 30px;
  }

  .icon {
    margin-right: 10px;

    @media (min-width: ${breakpoints.md}) {
      margin-right: 0;
      margin-bottom: 16px;
    }
    @media (min-width: ${breakpoints.lg}) {
      margin-right: 0;
      margin-bottom: 24px;
    }
  }

  .header {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    @media (min-width: ${breakpoints.md}) {
      margin-bottom: 8px;
      display: block;
    }
    @media (min-width: ${breakpoints.lg}) {
      margin-bottom: 16px;
    }
    @media (min-width: ${breakpoints.lg}) {
      margin-bottom: 24px;
    }
  }

  p {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
