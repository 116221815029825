import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, ButtonLink } from './style'

import ImageWebp from 'src/components/ImageWebp'

const Bussiness = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const arrayNumbers: number[] = [
    336, 336, 336, 456,
  ]

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5 order-md-last mb-4 mb-md-0 d-flex justify-content-center'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/inter-empresa/image.webp'
              altDescription='Homem sorrindo e segurando um cartão de crédito inter PJ'
              arrayNumbers={arrayNumbers}
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white fw-600'>
              Quer o Inter Loop na sua empresa?
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white'>
              Você também pode aproveitar o programa de pontos totalmente gratuito em sua Conta Empresarial, com flexibilidade para escolher os benefícios que mais combinam com seu negócio!
            </p>
            <ButtonLink
              href='/empresas/programa-de-pontos-para-empresas/'
              target='_blank'
              rel='noreferrer'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_04',
                  element_action: 'click button',
                  element_name: 'Quero Inter Loop na minha empresa',
                  section_name: 'Quer o Inter Loop na sua empresa?',
                  redirect_url: 'https://inter.co/empresas/programa-de-pontos-para-empresas/',
                })
              }}
            >
              Quero Inter Loop na minha empresa
            </ButtonLink>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Bussiness
