import React from 'react'
import InterCoIcons from 'src/components/UI/MarkdownIcon/InterCoIcons'
import acumulationData from '../../assets/data/acumulation.json'

import { orange } from 'src/styles/colors'

import { Section, Card } from './style'

type acumulationDataProps = {
  icon: string;
  title: string;
  description: string;
}

const Acumulation = () => {
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row justify-content-md-end'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 text-md-center'>
              Use seu cartão Inter Mastercard, acumule pontos e escolha os benefícios que mais combinam com você
            </h2>
          </div>
          <div className='col-12 col-md-6'>
            {
              acumulationData.map((item: acumulationDataProps) => (
                <Card key={item.icon}>
                  <div className='d-flex align-items-center header'>
                    <div className='icon'>
                      <InterCoIcons size='MD' width={26} height={26} icon={item.icon} color={orange.extra} />
                    </div>
                    <h3 className='fs-16 lh-20 fs-lg-20 lh-lg-25 fs-xl-24 lh-xl-30 text-grayscale--500 fw-600 mb-0'>
                      {item.title}
                    </h3>
                  </div>
                  <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--400 mb-0'>
                    {item.description}
                  </p>
                </Card>
              ))
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Acumulation
