import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { widths } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'

import pointsData from '../../assets/data/points.json'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import { Section, Button, ButtonLink, Card, ImageContainer } from './style'

type pointsDataProps = {
  image: string;
  alt: string;
  title: string;
  point_text: string;
  description: string;
}

type sectionProps = {
  setIsOpen: Function;
}

const Points = ({ setIsOpen }: sectionProps) => {
  const width = useWidth(300)

  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='pt-4 pb-5'>
      <div className='container'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-12 col-lg-10 col-xl-12 text-md-center px-xl-0'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600'>
              Como acumular pontos com o cartão Inter
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 mb-0'>
              No Inter Loop, suas compras no cartão de crédito geram pontos para você trocar por benefícios exclusivos. Veja como funciona:
            </p>
          </div>

          <div className='col-12 carroussel'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 2, partialVisibilityGutter: 70 }}
              lg={{ items: 4 }}
              xl={{ items: 4 }}
              itemClass='px-2'
            >
              {
                pointsData.map((item: pointsDataProps) => (
                  <Card key={item.title}>
                    <ImageContainer>
                      <img width='239px' height='197px' src={item.image} className='img' alt={item.alt} />
                    </ImageContainer>
                    <h3 className='text-grayscale--500 fw-600'>
                      {item.title}
                    </h3>
                    <span className='fs-13 lh-17 fs-md-16 lh-md-20 d-block text-orange--extra fw-600'>
                      {item.point_text}
                    </span>
                    <p
                      className='fs-12 mt-md-3 text-grayscale--500 mb-0'
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </Card>
                ))
              }
            </DefaultCarousel>
          </div>

          <div className='col-12 col-lg-10 col-xl-12 text-md-center mt-4'>
            <h2 className='fs-16 lh-20 fs-md-20 lh-md-25 fs-lg-24 lh-lg-30 fs-xl-32 lh-xl-40 text-grayscale--500 fw-600'>
              Não tem limite de crédito pré-aprovado?
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 mb-0'>
              Invista no CDB Mais Limite ou na Poupança Mais Limite para fazer compras na modalidade crédito e comece a acumular pontos.
            </p>
          </div>
          <div className='col-12 text-center'>
            {
              width < widths.md ? (
                <ButtonLink
                  href='https://intergo.app/cf3d6ddf'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_05',
                      element_action: 'click button',
                      element_name: 'Acumular Pontos',
                      section_name: ' Como acumular pontos com o cartão Inter',
                    })
                  }}
                >
                  Acumular Pontos
                </ButtonLink>
              ) : (
                <Button
                  onClick={() => {
                    setIsOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_05',
                      element_action: 'click button',
                      element_name: 'Acumular Pontos',
                      section_name: ' Como acumular pontos com o cartão Inter',
                    })
                  }}
                >
                  Acumular Pontos
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Points
