import styled from 'styled-components'
import { orange, white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-color: ${orange.extra};
  min-height: 356px;
  display: flex;
  align-items: center;
  background-position-x: right;

  h1 {
    margin-bottom: 16px;
  }

  @media(min-width: ${breakpoints.md}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/hero-loop-mulher/image.webp');
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 336px;
  }
  @media(min-width: ${breakpoints.lg}) {
    min-height: 456px;
  }
  @media(min-width: ${breakpoints.xl}) {
    min-height: 640px;

    h1 {
      margin-bottom: 24px;
    }
  }
`
export const ImageContainer = styled.div`
  width: 153px;
  margin-bottom: 8px;

  img {
    width: 100%;
  }

  @media (min-width: ${breakpoints.md}) {
    width: 185px;
  }
  @media (min-width: ${breakpoints.lg}) {
    width: 223px;
  }
  @media (min-width: ${breakpoints.xl}) {
    width: 311px;
  }
`
export const Buttom = styled.button`
  width: 100%;
  height: 48px;
  background: ${white};
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Sora';
  color: ${orange.extra};
  border: none;
  margin-top: 32px;

  &:focus {
    outline: none;
  }

  @media (min-width: ${breakpoints.xl}) {
    margin-top: 40px;
  }
`
export const ButtonLink = styled.a`
  width: 100%;
  height: 48px;
  background: ${white};
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Sora';
  color: ${orange.extra};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-top: 32px;

  &:hover {
    color: ${orange.extra};
  }
`
