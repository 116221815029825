import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { gray, orange, white } from 'src/styles/colors'

export const Section = styled.section`
  background-color: ${gray[400]};
  display: flex;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  min-height: 360px;

  h2 {
    margin-bottom: 16px;
  }

  @media (min-width: ${breakpoints.md}) {
    min-height: 408px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/bg-viagens-dobra03/image.webp');
    background-size: contain;
    background-position-y: center;
    background-position-x: left;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 495px;
    background-size: 800px;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 636px;
    background-size: auto;
    background-position: 26% 50%;
  }
`
export const Button = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background: ${orange.extra};
  font-size: 14px;
  line-height: 17px;
  color: ${white};
  border: none;
  margin-top: 32px;

  &:focus {
    outline: none;
  }
`
export const ButtonLink = styled.a`
   width: 100%;
  height: 48px;
  border-radius: 8px;
  background: ${orange.extra};
  font-size: 14px;
  line-height: 17px;
  color: ${white};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  &:hover {
    text-decoration: none;
    color: ${white};
  }
`
