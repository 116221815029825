import React from 'react'
import Img from 'gatsby-image'
import { widths } from 'src/styles/breakpoints'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import PageQuery from '../../pageQuery'

import { Section, Buttom, ButtonLink, ImageContainer } from './style'

type sectionProps = {
  setIsOpen: Function;
}

export const Hero = ({ setIsOpen }: sectionProps) => {
  const data = PageQuery()
  const width = useWidth(300)

  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section
      className='py-4'
      role='img'
      aria-label='Mulher sorrindo e segurando um cartão de crédito inter'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h1 className='fs-24 lh-30 fs-xl-32 lh-xl-40 fw-400 text-white'>
              <ImageContainer>
                <Img fluid={data.logoLoop.fluid} alt='loop' />
              </ImageContainer>
              Chegou o programa de pontos do Inter
            </h1>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white mb-0'>
              Acumule pontos para trocar por milhas, desconto na fatura do cartão, cashback extra em compras no Inter Shop,
              dólares na Global Account e até por investimentos.
            </p>
            {
              width < widths.md ? (
                <ButtonLink
                  href='https://intergo.app/cf3d6ddf'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      element_action: 'click button',
                      element_name: 'Quero ganhar pontos',
                      section_name: 'Loop Chegou o programa de pontos do Inter',
                      redirect_url: 'https://intergo.app/cf3d6ddf',
                    })
                  }}
                >
                  Quero ganhar pontos
                </ButtonLink>
              ) : (
                <Buttom
                  onClick={() => {
                    setIsOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      element_action: 'click button',
                      element_name: 'Quero ganhar pontos',
                      section_name: 'Loop Chegou o programa de pontos do Inter',
                    })
                  }}
                >
                  Quero ganhar pontos
                </Buttom>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
