import React from 'react'

import { widths } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, Button, ButtonLink } from './style'

type sectionProps = {
  setIsOpen: Function;
}

const Travel = ({ setIsOpen }: sectionProps) => {
  const width = useWidth(300)

  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section
      className='py-5'
      role='img'
      aria-label='Ícone de avião laranja'
    >
      <div className='container'>
        <div className='row justify-content-md-end'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600'>
              Viva a viagem dos seus sonhos com o Inter Loop
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--400'>
              Converta seus pontos em milhas na Azul Linhas Aéreas.
            </p>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--400'>
              Junte a partir de 5.000 pontos e troque no Programa TudoAzul. A transferência é de 1 para 1: seus pontos têm o mesmo valor nos dois programas, para você aproveitar sem perder nada.
            </p>
            {
              width < widths.md ? (
                <ButtonLink
                  href='https://intergo.app/cf3d6ddf'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_03',
                      element_action: 'click button',
                      element_name: 'Acumular Pontos',
                      section_name: 'Viva a viagem dos seus sonhos com o Inter Loop',
                      redirect_url: 'https://intergo.app/cf3d6ddf',
                    })
                  }}
                >
                  Acumular Pontos
                </ButtonLink>
              ) : (
                <Button
                  onClick={() => {
                    setIsOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_03',
                      element_action: 'click button',
                      element_name: 'Acumular Pontos',
                      section_name: 'Viva a viagem dos seus sonhos com o Inter Loop',
                    })
                  }}
                >
                  Acumular Pontos
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Travel
