import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { green, white } from 'src/styles/colors'

export const Section = styled.section`
  min-height: 580px;
  background: ${green[600]};
  display: flex;
  align-items: center;

  h2 {
    margin-bottom: 16px;
  }

  @media (min-width: ${breakpoints.md}) {
    min-height: 416px;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 504px;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 648px;
  }
`
export const ButtonLink = styled.a`
  width: 100%;
  height: 48px;
  background: ${green[500]};
  border-radius: 8px;
  color: ${white};
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${breakpoints.xl}) {
    margin-top: 40px;
  }

  &:hover {
    text-decoration: none;
    color: ${white};
  }
`
