import styled from 'styled-components'
import { gray } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  min-height: 1230px;
  background: ${gray[400]} url('https://central-imagens.bancointer.com.br/images-without-small-versions/celular-cartao-app/image.webp');
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: end;

  h2 {
    margin-bottom: 16px;
  }
  
  @media (min-width: ${breakpoints.md}) {
    min-height: 768px;
    align-items: center;
    justify-content: flex-end;
    align-items: center;
    background-position-y: 70%;
    background-size: 372px;

    h2 {
      margin-bottom: 32px;
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 817px;
    background-size: 500px;

    h2 {
      margin-bottom: 40px;
    }
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 975px;
    background-size: 612px;

    h2 {
      margin-bottom: 64px;
    }
  }
`
export const Card = styled.div`
  .header {
    margin-bottom: 8px;
  }

  .icon {
    margin-right: 8px;
  }

  & + div {
    margin-top: 24px;
  }

  @media (min-width: ${breakpoints.md}) {
    .header {
      margin-bottom: 10px;
    }

    & + div {
      margin-top: 40px;
    }
  }
`
